import React, {useEffect, useState} from "react";
import {Table} from "antd";
import {getAllScheduledJobs} from "../service/api/userAPI";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import {formatTimestamp, convertTimestampToElapsedTime, convertSecondsToTimeUnit} from "../util/DateUtils";
import webSocketService from "../service/ws/websocketService";
import DynamicTime from "../component/dynamicTime";

const ScheduledTaskTable = () => {
    const [dataSource, setDataSource] = useState([]);
    const [loading, setLoading] = useState(true);
    const [updatedRowId, setUpdatedRowId] = useState(null);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            width: 50,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            width: 100,
        },
        {
            title: 'Description',
            dataIndex: 'description',
            width: 200,
        },
        {
            title: 'Enable',
            render: row => row.enabled ? <CheckOutlined style={{ color: 'green' }} /> : <CloseOutlined style={{ color: 'red' }} />,
            width: 100,
        },
        {
            title: 'Working hours',
            render: row => <span>From {row.workingPeriodStart} to {row.workingPeriodEnd}</span>,
            width: 200,
        },
        {
            title: 'Run every',
            render: row => <span>{convertSecondsToTimeUnit(row.runEverySec)}</span>,
            width: 200,
        },
        {
            title: 'Last Run',
            render: row => (<DynamicTime timestamp={row.lastTimestamp}/>),
            width: 200,
        }
    ];
    const updateTable = async () => {
        setLoading(true);
        getAllScheduledJobs(tableParams.pagination.current - 1, tableParams.pagination.pageSize).then(
            (res) => {
                setDataSource(res.data.content.map(item => ({ key: item.id, id: item.id, name: item.name, description: item.description, enabled: item.enabled, runEverySec: item.runEverySec,
                        workingPeriodStart: item.workingPeriodStart, workingPeriodEnd: item.workingPeriodEnd, lastTimestamp: new Date(item.lastTimestamp).toLocaleString() }
                )));
                setTableParams({
                    ...tableParams,
                    pagination: {
                        ...tableParams.pagination,
                        total: res.data.totalElements,
                        showTotal: (total) => `Total ${total} scheduled jobs`
                    },
                });
                setLoading(false);
            }
        );
    }
    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setDataSource([]);
        }
    };
    const updateRowData = (updatedData) => {
        setDataSource((prevData) =>
            prevData.map((item) =>
                item.id === updatedData.id ? { ...item, ...updatedData } : item
            )
        );
        // Set the updatedRowId to trigger the animation for that row
        setUpdatedRowId(updatedData.id);
        // Reset the updatedRowId after a short delay so that the animation can complete
        setTimeout(() => setUpdatedRowId(null), 500);
    };
    useEffect(() => {
        updateTable();
    }, [JSON.stringify(tableParams)]);
    useEffect(() => {
        // Connect to WebSocket server
        webSocketService.connect( () => {
            webSocketService.subscribe("/topic/scheduleJobs", (message) => {
                console.log(message.payload);
                // Update the row based on the message
                updateRowData(message.payload);
            });
        });

        return () => {
            webSocketService.disconnect();
        };
    }, []);
    return (
        <>
            <Table rowKey="key"
                   columns={columns}
                   dataSource={dataSource}
                   pagination={tableParams.pagination}
                   loading={loading}
                   onChange={handleTableChange}
                   rowClassName={(r) => r.id === updatedRowId ? "updated-row" : ""
                   }
            >

            </Table>
        </>
    )
}
export default ScheduledTaskTable;