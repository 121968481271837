import React, {useState} from "react";
import {Card, Col, Form, Input, Modal, Row, Image, Upload} from "antd";
import {askGPT} from "../service/api/userAPI";
import { Radio } from 'antd';
import JsonViewer from "../component/JsonViewer";
import {EditFilled, PlusOutlined, ToolFilled} from "@ant-design/icons";
import ReactMarkdown from 'react-markdown';
const GptTestPromptModal = ({buttonName, title, prompt, callback}) => {
    const [open, setOpen] = useState(false);
    const [lan, setLan] = useState("CN");
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState("");
    const [form] = Form.useForm();
    const [fileList, setFileList] = useState([])
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
    const showModal = () => {
        setOpen(true)
    }
    const handleTest = () => {
        form.submit();  // This will trigger form submission
    };
    const onFinish = (values) => {
        console.log('Form Submitted with values:', values);
        setLoading(true)
        if(fileList.length > 0) {
            let file = fileList[0]
            if (!file.url && !file.preview) {
                file.preview = getBase64(file.originFileObj);
            }
            file.preview.then(result => {
                values["attachment"] = result;
                askGPT(prompt.id, values, lan).then(
                    res => {
                        setResult(res.data)
                    }
                ).finally(() => {
                    setLoading(false)
                })
            })
        } else {
            askGPT(prompt.id, values, lan).then(
                res => {
                    setResult(res.data)
                }
            ).finally(() => {
                setLoading(false)
            })
        }
    }
    const extractPlaceHolder = (sentence) => {
        const regex = /\$\{(\w+)\}/g;
        const placeholders = new Set();
        let match;
        while ((match = regex.exec(sentence)) !== null) {
            placeholders.add(match[1]);
        }
        return Array.from(placeholders);
    };

    const PlaceholderForm = ({ sentence }) => {
        const placeholders = extractPlaceHolder(sentence);
        return (
            <Form
            form={form}
            name="placeholder-form"
            onFinish={onFinish}>
                {placeholders.map((placeholder) => (
                    <Form.Item key={placeholder} name={placeholder} label={placeholder} rules={[{required:true}]}>
                        <Input/>
                    </Form.Item>
                ))}
            </Form>
        );
    };
    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };
    const customUploadFile = (file) => {

    }
    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
            }}
            type="button"
        >
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </button>
    );
    return (
        <>
            <a onClick={showModal} style={{display: 'flex', alignItems: 'center'}}>
                <ToolFilled style={{marginRight: 8}}/> {/* Adjust margin as needed */}
                {buttonName}
            </a>
            <Modal
                title={title}
                centered
                open={open}
                onOk={handleTest}
                okText={"Test"}
                onCancel={() => setOpen(false)}
                aria-hidden={!open}
                width={1000}
            >
                <Card>
                    <Row gutter={16}>
                        <Col span={12}>
                            <PlaceholderForm sentence={prompt.promptChinese}/>
                            <Radio.Group
                                block
                                options={["CN", "EN"]}
                                defaultValue="CN"
                                optionType="button"
                                buttonStyle="solid"
                                onChange={(e) => setLan(e.target.value)}
                                style={{marginBottom: "10px"}}
                            />
                            <Card style={{marginBottom: "8px"}}>
                                {lan === "CN" ? prompt.promptChinese : prompt.promptEnglish}
                            </Card>
                            <Upload
                                customRequest={({ file, onSuccess }) => {
                                    onSuccess("Upload successful");
                                }}
                                listType="picture-card"
                                fileList={fileList}
                                onPreview={handlePreview}
                                onChange={handleChange}
                            >
                                {fileList.length >= 1 ? null : uploadButton}
                            </Upload>
                        </Col>

                        <Col span={12}>
                            {prompt.jsonMode?<JsonViewer jsonString={JSON.stringify(result)} loading={loading}/>:
                                <Card loading={loading}><ReactMarkdown>{result}</ReactMarkdown></Card>}
                        </Col>
                    </Row>
                </Card>
                {previewImage && (
                    <Image
                        wrapperStyle={{
                            display: 'none',
                        }}
                        preview={{
                            visible: previewOpen,
                            onVisibleChange: (visible) => setPreviewOpen(visible),
                            afterOpenChange: (visible) => !visible && setPreviewImage(''),
                        }}
                        src={previewImage}
                    />
                )}
            </Modal>
        </>
    )
}
export default GptTestPromptModal