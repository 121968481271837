import React from "react";
import './brandLogo.css';  // Assume you have a CSS file for styles

const BrandLogo = ({ collapsed }) => (
    <div className={`demo-logo-vertical ${collapsed ? "collapsed" : ""}`}>
        <img src="/logo.png" alt="Logo" className="logo-img" />
        {!collapsed && (
            <span className="logo-text">
        Vocab<span className="highlighted">AI</span>
      </span>
        )}
    </div>
);

export default BrandLogo;
