// CountryLocation.js
import React from 'react';
import './countryLocation.css'
import {Tooltip} from "antd";

// Helper function to get country name from code
const getCountryName = (countryCode) => {
    const countryNames = {
        GB: 'Great Britain',
        AU: 'Australia',
        US: 'United States',
        CA: 'Canada',
        DE: 'Germany',
        FR: 'France',
        ES: 'Spain',
        IT: 'Italy',
        JP: 'Japan',
        CN: 'China',
        IN: 'India',
        BR: 'Brazil',
        RU: 'Russia',
        MX: 'Mexico',
        KR: 'South Korea',
        NL: 'Netherlands',
        SE: 'Sweden',
        CH: 'Switzerland',
        ZA: 'South Africa',
        AR: 'Argentina',
        SG: 'Singapore',
        HK: 'Hong Kong',
        NZ: 'New Zealand',
        BE: 'Belgium',
        DK: 'Denmark',
        NO: 'Norway',
        FI: 'Finland',
        IE: 'Ireland',
        AT: 'Austria',
        PT: 'Portugal',
        GR: 'Greece',
        TR: 'Turkey',
        PL: 'Poland',
        TH: 'Thailand',
        MY: 'Malaysia',
        PH: 'Philippines',
        ID: 'Indonesia',
        SA: 'Saudi Arabia',
        EG: 'Egypt',
        NG: 'Nigeria',
        KE: 'Kenya',
        IL: 'Israel',
        PK: 'Pakistan',
        BD: 'Bangladesh',
        VN: 'Vietnam',
        TW: 'Taiwan',
        AE: 'United Arab Emirates',
        CZ: 'Czech Republic',
        HU: 'Hungary',
        RO: 'Romania',
        SK: 'Slovakia',
        CL: 'Chile',
        CO: 'Colombia',
        VE: 'Venezuela',
    };

    return countryNames[countryCode] || 'Unknown Country';
};

// Compact CountryLocation component for a table
const CountryLocation = ({ countryCode, region, city }) => {
    return (
        <div className="location-info">
            {/* Display the country flag icon */}

            {/* Display the city, region, and country name */}
            {/*<span className="location-text">*/}
            {/*    {city}, {region}, {getCountryName(countryCode)}*/}
            {/*</span>*/}
            <Tooltip placement="top" title={`${city}, ${region}, ${getCountryName(countryCode)}`}>
                <span className={`flag-icon flag-icon-${countryCode.toLowerCase()}`}/>
            </Tooltip>
        </div>
    );
};

export default CountryLocation;
