import {Image, Table} from "antd";
import {getAllImageList} from "../service/api/userAPI";
import React, {useEffect, useState} from "react";
import ImageGenerateModal from "../modal/ImageGenerateModal";

const ImageTable = () => {
    const [dataSource, setDataSource] = useState([]);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });

    const [loading, setLoading] = useState(true);

    const updateTable = async () => {
        setLoading(true);
        getAllImageList(tableParams.pagination.current - 1, tableParams.pagination.pageSize).then(
            (res) => {
                setDataSource(res.data.content.map(item => ({ key: item.id, id: item.id, prompt: item.prompt, base64Img: item.base64Img, platform: item.platform, seed: item.seed,
                        cost: item.cost, timestamp: new Date(item.timestamp).toLocaleString() }
                )));
                setTableParams({
                    ...tableParams,
                    pagination: {
                        ...tableParams.pagination,
                        total: res.data.totalElements,
                        showTotal: (total) => `Total ${total} images`
                    },
                });
                setLoading(false);
            }
        );
    }
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            width: 50,
        },
        {
            title: 'Platform',
            dataIndex: 'platform',
            width: 100,
        },
        {
            title: 'Prompt',
            dataIndex: 'prompt',
            width: 400,
        },
        {
            title: 'Cost',
            render: row => <span>$ {row.cost}</span>,
            width: 150,
        },
        {
            title: 'Seed',
            dataIndex: 'seed',
            width: 150,
        },
        {
            title: 'Image',
            render: row => (
                <Image src={`data:image/jpeg;base64,${row.base64Img}`} width={150} />
            ),
        },
        {
            title: 'Created At',
            dataIndex: 'timestamp',
            width: 200,
        }
    ];
    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setDataSource([]);
        }
    };
    useEffect(() => {
        updateTable();
    }, [JSON.stringify(tableParams)]);
    return (
        <>
            <ImageGenerateModal buttonName={"Generate AI Image"} title={"Generate AI Image"} callback={() => getAllImageList(1, 10)}/>
            <Table rowKey="key"
                   columns={columns}
                   dataSource={dataSource}
                   pagination={tableParams.pagination}
                   loading={loading}
                   onChange={handleTableChange}>

            </Table>
        </>
    )
};
export default ImageTable;