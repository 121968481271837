import {Dropdown, message, Progress, Space, Table, Tag} from "antd";
import axios from "axios";
import {baseURL} from "../service/ApiService";
import {getAllUserList} from "../service/api/userAPI";
import React, {useEffect, useState} from "react";
import BookEditFormModal from "../modal/BookEditFormModal";
import {AppleOutlined, DownOutlined, MobileOutlined, WechatOutlined} from "@ant-design/icons";
import UserEditFormModal from "../modal/UserEditFormModal";
import UserViewStudyProgressModal from "../modal/UserViewStudyProgressModal";
import SendNotificationModal from "../modal/SendNotificationModal";
import GeneralConfirmModal from "../modal/GeneralConfirmModal";
import ViewLogsModal from "../modal/ViewLogsModal";
import CountryLocation from "../badge/countryLocation";

const UserTable = () => {
    const [dataSource, setDataSource] = useState([]);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });

    const [loading, setLoading] = useState(true);
    const updateTable = async () => {
        setLoading(true);
        getAllUserList(tableParams.pagination.current - 1, tableParams.pagination.pageSize).then(
            (res) => {
                setDataSource(res.data.content.map(item => ({ key: item.id, id: item.id, name: item.name, country: item.country, region: item.region, city: item.city,
                    email: item.email, userType: item.userType, timestamp: new Date(item.createDate).toLocaleString(),
                        dailyUsedToken: item.dailyUsedToken, totalDailyToken: item.totalDailyToken, deviceList: item.deviceList, subscriptionId: item.subscriptionId }
                )));
                setTableParams({
                    ...tableParams,
                    pagination: {
                        ...tableParams.pagination,
                        total: res.data.totalElements,
                        showTotal: (total) => `Total ${total} users`
                    },
                });
                setLoading(false);
            }
        );
    }
    const syncUserToPrivate = (userId) => {
        axios.get(`${baseURL}/api/web/sync-user-words-list?userId=${userId}`).catch((reason) => {
            message.error(reason.response.data.detail)
        });
    }
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            width: 50,
        },
        {
            title: 'Type',
            dataIndex: 'userType',
            width: 50,
            render: (text) => (text === 'wechat'?<WechatOutlined />:<AppleOutlined />)
        },
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'IP',
            render: row => <>{row.country?<CountryLocation countryCode={row.country} region={row.region} city={row.city}/>:''}</>,
        },
        {
        title: 'Plan',
            render: row => row.subscriptionId?<Tag color="gold">{row.subscriptionId}</Tag>:'Normal',
            width: 200,
        },
        {
            title: 'Email',
            dataIndex: 'email',
        },
        {
            title: 'Daily Token Usage',
            render: row => <span>{row.dailyUsedToken + "/" + (row.subscriptionId?'∞':row.totalDailyToken)}</span>,
        },
        {
            title: 'Devices',
            width: 250,
            render: row => row.deviceList?.map(e => (<Tag icon={<MobileOutlined />} color="#3b5999">{e}</Tag>)),
        },
        {
            title: 'Created At',
            dataIndex: 'timestamp',
            width: 200,
        },
        {
            title: 'Action',
            key: 'operation',
            fixed: 'right',
            width: 100,
            render: (row) => {
                return (
                    <Dropdown
                        menu={{
                            items: [
                                {
                                    key: 'updateToken',
                                    label: (<UserEditFormModal buttonName={'Update Token'} user={row} callback={updateTable} />),
                                },
                                {
                                    key: 'viewStudyProgress',
                                    label: (<UserViewStudyProgressModal buttonName={'View Study Progress'} user={row} callback={updateTable} /> ),
                                },
                                {
                                    key: 'sendNotification',
                                    label: (<SendNotificationModal buttonName={'Send notifications'} user={row} /> ),
                                    disabled: row.deviceList.length === 0
                                },
                                {
                                    key: 'viewLog',
                                    label: (<ViewLogsModal buttonName={'View Logs'} user={row} /> ),
                                },
                            ],
                        }}
                    >
                        <a onClick={(e) => e.preventDefault()}>
                            <Space>
                                Action
                                <DownOutlined />
                            </Space>
                        </a>
                    </Dropdown>
                );
            }
        },
    ];
    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setDataSource([]);
        }
    };
    useEffect(() => {
        updateTable();
    }, [JSON.stringify(tableParams)]);
    return (
        <>
            <Table rowKey="key"
                   columns={columns}
                   dataSource={dataSource}
                   pagination={tableParams.pagination}
                   loading={loading}
                   onChange={handleTableChange}>

            </Table>
        </>
    )
};
export default UserTable;