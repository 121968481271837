import { Stomp } from "@stomp/stompjs";
import SockJS from "sockjs-client";
import {baseURL} from "../ApiService";

class WebSocketService {
    constructor() {
        this.stompClient = null;
        this.connected = false;
        this.subscriptions = {};
        this.retryDelay = 1000; // Initial delay for reconnection in ms
        this.url = `${baseURL}/api/ws`
    }

    connect(onConnectCallback = () => {}) {
        console.log("Attempting to connect to WebSocket...");

        // Create a WebSocket instance using SockJS

        // Pass the SockJS WebSocket to Stomp client
        this.stompClient = Stomp.over(() => new SockJS(this.url));
        this.stompClient.reconnect_delay = 1000;
        // Attempt to connect to the STOMP server
        this.stompClient.connect({}, () => {
            console.log("WebSocket connected");
            this.connected = true;
            this.retryDelay = 1000; // Reset retry delay on successful connection
            onConnectCallback(); // Callback after successful connection
        }, (error) => {
            console.error("WebSocket connection failed:", error);
            this.connected = false;
            this.reconnect(this.url, onConnectCallback); // Retry if connection fails
        });
    }

    reconnect(url, onConnectCallback) {
        console.log(`Reconnection attempt in ${this.retryDelay / 1000}s...`);
        setTimeout(() => {
            console.log("Reconnecting...");
            this.connect(url, onConnectCallback); // Attempt reconnection
            this.retryDelay = Math.min(this.retryDelay * 2, 16000); // Exponential backoff
        }, this.retryDelay);
    }

    disconnect() {
        if (this.stompClient) {
            console.log("Disconnecting WebSocket...");
            this.stompClient.disconnect(() => {
                console.log("WebSocket disconnected");
                this.connected = false;
            });
        }
    }

    subscribe(topic, callback) {
        if (this.stompClient && this.connected) {
            if (!this.subscriptions[topic]) {
                this.subscriptions[topic] = this.stompClient.subscribe(topic, (message) => {
                    console.log("Received message:", message);
                    callback(JSON.parse(message.body)); // Parse and pass the message body
                });
            }
        } else {
            console.error("WebSocket is not connected. Call connect() first.");
        }
    }

    unsubscribe(topic) {
        if (this.subscriptions[topic]) {
            this.subscriptions[topic].unsubscribe();
            delete this.subscriptions[topic];
        }
    }

    send(destination, message) {
        if (this.stompClient && this.connected) {
            this.stompClient.send(destination, {}, JSON.stringify(message)); // Send message
        } else {
            console.error("WebSocket is not connected. Call connect() first.");
        }
    }
}

const webSocketService = new WebSocketService();
export default webSocketService;
