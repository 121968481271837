// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* App.css */
.location-info {
    display: flex;
    align-items: center;
    white-space: nowrap; /* Prevent text from wrapping to keep it compact */
    font-size: 0.85rem; /* Reduce overall text size */
}

.flag-icon {
    margin-right: 5px; /* Reduce space between flag and text */
    font-size: 1rem; /* Smaller flag icon */
}

.location-text {
    color: #333;
}
`, "",{"version":3,"sources":["webpack://./src/badge/countryLocation.css"],"names":[],"mappings":"AAAA,YAAY;AACZ;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB,EAAE,kDAAkD;IACvE,kBAAkB,EAAE,6BAA6B;AACrD;;AAEA;IACI,iBAAiB,EAAE,uCAAuC;IAC1D,eAAe,EAAE,sBAAsB;AAC3C;;AAEA;IACI,WAAW;AACf","sourcesContent":["/* App.css */\r\n.location-info {\r\n    display: flex;\r\n    align-items: center;\r\n    white-space: nowrap; /* Prevent text from wrapping to keep it compact */\r\n    font-size: 0.85rem; /* Reduce overall text size */\r\n}\r\n\r\n.flag-icon {\r\n    margin-right: 5px; /* Reduce space between flag and text */\r\n    font-size: 1rem; /* Smaller flag icon */\r\n}\r\n\r\n.location-text {\r\n    color: #333;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
