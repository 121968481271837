import {Image, Input, Spin, Table} from "antd";
import {generateWordImage, getAllImageList, searchBookWords} from "../service/api/userAPI";
import React, {useEffect, useState} from "react";
import GptModelEditModal from "../modal/GptModelEditModal";
import ImageGenerateModal from "../modal/ImageGenerateModal";
import {useParams} from "react-router-dom";
import Search from "antd/es/input/Search";
import DataCard from "../component/DataCard";

const WordTable = () => {
    const [dataSource, setDataSource] = useState([]);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const [keyWord, setKeyWord] = useState("");
    const { bookId } = useParams();
    const [loading, setLoading] = useState(true);
    const [inProgressList, setInProgressList] = useState([]);

    const updateTable = async () => {
        setLoading(true);
        searchBookWords(tableParams.pagination.current - 1, tableParams.pagination.pageSize, keyWord, bookId).then(
            (res) => {
                setDataSource(res.data.content.map(item => ({ key: item.id, id: item.id, word: item.word, data: item.data, variants: item.variants, uk_audio: item.uk_audio,
                    us_audio: item.us_audio, image: item.image }
                )));
                setTableParams({
                    ...tableParams,
                    pagination: {
                        ...tableParams.pagination,
                        total: res.data.totalElements,
                        showTotal: (total) => `Total ${total} words`
                    },
                });
                setLoading(false);
            }
        );
    }
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            width: 50,
        },
        {
            title: 'Word',
            dataIndex: 'word',
            width: 100,
        },
        {
            title: 'Data',
            render: (row) => <DataCard word={row.word} entry={JSON.parse(row.data)[0]} />,
        },
        {
            title: 'Image',
            render: row => (
                inProgressList.includes(row.id) ? (
                    <Spin />
                ) : (
                    row.image ? (
                        <Image src={`data:image/jpeg;base64,${row.image}`} width={512} />
                    ) : null // or use <> </> if you prefer an empty fragment
                )
            ),
            width: 600,
        },
        {
            title: 'Action',
            render: row => (
                <a onClick={() => {
                    setInProgressList(prev => [...prev, row.id])
                    generateWordImage(row.id, {'word': row.word, 'definition': JSON.stringify(JSON.parse(row.data)[0].data.definitions[0]), 'pos': JSON.stringify(JSON.parse(row.data)[0].POS)})
                        .then(updateTable)
                        .finally(() => setInProgressList(prev => prev.filter(id => id !== row.id)));
                }}>
                    Generate Image
                </a>
            )
        }

    ];
    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setDataSource([]);
        }
    };
    useEffect(() => {
        updateTable();
    }, [JSON.stringify(tableParams), keyWord]);
    return (
        <>
            {/*<ImageGenerateModal buttonName={"Generate AI Image"} title={"Generate AI Image"} callback={() => getAllImageList(1, 10)}/>*/}
            <Search
                placeholder="input search text"
                allowClear
                onSearch={e => setKeyWord(e)}
                style={{
                    width: 200,
                }}
            />
            <Table rowKey="key"
                   columns={columns}
                   dataSource={dataSource}
                   pagination={tableParams.pagination}
                   loading={loading}
                   onChange={handleTableChange}>
            </Table>
        </>
    )
};
export default WordTable;