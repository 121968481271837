import {DownOutlined, SyncOutlined} from '@ant-design/icons';
import {Table, Col, Tag, Dropdown, Space, Row} from 'antd';
import axios from 'axios';
import {baseURL} from '../service/ApiService';
import React, { useEffect, useState } from 'react';
import GptModelEditModal from "../modal/GptModelEditModal";
import GptPromptEditModal from "../modal/GptPromptEditModal";
import GptTestPromptModal from "../modal/GptTestPromptModal";
const GptPromptTable = () => {
    const updateTable = async () => {
        axios.get(`${baseURL}/api/prompt?page=0&size=10`).then(
            (res) => {
                console.log(res.data.content);
                setDataSource(res.data.content.map(item => ({ key: item.id, id: item.id, description: item.description, promptChinese: item.promptChinese, promptEnglish: item.promptEnglish, jsonMode: item.jsonMode, config: item.config })));
                setLoading(false);
            }
        );
    }
    useEffect(() => {
        updateTable();
        getCurrentModel();
    }, [])
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            fixed: 'left',
        },
        {
            title: 'Name',
            dataIndex: 'description',
            width: 100,
        },
        {
            title: 'Prompt text (Chinese)',
            dataIndex: 'promptChinese',
            editable: true,
            render: (text) => {
                const regex = /\${([^}]+)}/g;
                const boldText = text.replace(regex, '<strong>$&</strong>');
                return <div dangerouslySetInnerHTML={{ __html: boldText }} />;
            },
        },
        {
            title: 'Prompt text (English)',
            dataIndex: 'promptEnglish',
            editable: true,
            render: (text) => {
                const regex = /\${([^}]+)}/g;
                const boldText = text.replace(regex, '<strong>$&</strong>');
                return <div dangerouslySetInnerHTML={{ __html: boldText }} />;
            },
        },
        {
            title: 'Json Mode',
            width: 100,
            render: row => row.jsonMode?'Yes':'No',
        },
        {
            title: 'Action',
            key: 'operation',
            fixed: 'right',
            width: 100,
            render: (row) => {
                return (
                    <Dropdown
                        menu={{
                            items: [
                                {
                                    key: 'editPrompt',
                                    label: (<GptPromptEditModal buttonName={'Edit Prompt'} title={'Edit Prompt'} prompt={row} callback={updateTable} />),
                                },
                                {
                                    key: 'testPrompt',
                                    label: (<GptTestPromptModal buttonName={'Test Prompt'} title={row.description.toUpperCase()} prompt={row} callback={updateTable} />),
                                },
                            ],
                        }}
                    >
                        <a onClick={(e) => e.preventDefault()}>
                            <Space>
                                Action
                                <DownOutlined />
                            </Space>
                        </a>
                    </Dropdown>
                );
            }
        },
    ];
    const [dataSource, setDataSource] = useState([]);
    const [loading, setLoading] = useState(true);
    const [gptModel, setGptModel] = useState(null)
    const getCurrentModel = async () => {
        axios.get(`${baseURL}/api/web/current-model`).then(
            (res) => {
                console.log(res.data);
                setGptModel(res.data);
                setLoading(false)
            }
        );
    }

    return (
        <>
            <Row justify="space-between" align="bottom" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Col>
                    <GptModelEditModal model={gptModel?.id} buttonName={"Choose GPT Model"} title={"Choose GPT Model"} callback={() => getCurrentModel()}/>

                </Col>
                <Col>
                    <Tag icon={<SyncOutlined spin />} color="processing">
                        Powered by {gptModel?.modelName}
                    </Tag>
                </Col>
            </Row>
            <Table rowKey="key"
                   columns={columns}
                   dataSource={dataSource}
                   loading={loading}>

            </Table>
        </>
    );
};
export default GptPromptTable;