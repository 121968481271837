import axios from "axios";
import {baseURL} from "../ApiService";

export const getAllUserList = async (currentPage, pageSize) => {
    return axios.get(`${baseURL}/api/user/all?page=${currentPage}&size=${pageSize}`)
}

export const getAllImageList = async (currentPage, pageSize) => {
    return axios.get(`${baseURL}/api/web/getImgAI-history?page=${currentPage}&size=${pageSize}`)
}

export const generateImageViaGetImg = async (prompt, size) => {
    return axios.get(`${baseURL}/api/web/getImgAI?prompt=${prompt}&size=${size}`)
}

export const askGPT = async (promptId, payload, lan) => {
    return axios.post(`${baseURL}/api/prompt/test?promptId=${promptId}&lan=${lan}`, payload)
}

export const searchBookWords = async (page, size, word) => {
    return axios.get(`${baseURL}/api/web/search-book-word?page=${page}&size=${size}&word=${word}`)
}

export const generateWordImage = async (wordId, payload) => {
    return axios.post(`${baseURL}/api/web/update-book-image?wordId=${wordId}`, payload)
}

export const googleTTS = async (text) => {
    return axios.get(`${baseURL}/api/web/google-tts?text=${text}&lan=en-AU&voice=en-AU-Standard-C`)
}