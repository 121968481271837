// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.demo-logo-vertical {
    height: 40px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #01284e;
    padding: 0 16px;
}

.logo-img {
    height: 24px;
    width: 24px;
    margin-right: 8px;
    border-radius: 4px;
}

.logo-text {
    color: #fff;
    font-size: 18px;
    font-weight: bold;
}

.highlighted {
    color: #40a9ff;
}

.demo-logo-vertical.collapsed .logo-text {
    display: none;
}
`, "",{"version":3,"sources":["webpack://./src/badge/brandLogo.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,cAAc;IACd,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".demo-logo-vertical {\r\n    height: 40px;\r\n    margin: 0 auto;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    background: #01284e;\r\n    padding: 0 16px;\r\n}\r\n\r\n.logo-img {\r\n    height: 24px;\r\n    width: 24px;\r\n    margin-right: 8px;\r\n    border-radius: 4px;\r\n}\r\n\r\n.logo-text {\r\n    color: #fff;\r\n    font-size: 18px;\r\n    font-weight: bold;\r\n}\r\n\r\n.highlighted {\r\n    color: #40a9ff;\r\n}\r\n\r\n.demo-logo-vertical.collapsed .logo-text {\r\n    display: none;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
