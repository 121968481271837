import React, {useState} from "react";
import {Card, Col, Form, Input, Modal, Row} from "antd";
import {askGPT} from "../service/api/userAPI";
import { Radio } from 'antd';
import JsonViewer from "../component/JsonViewer";
import {EditFilled, ToolFilled} from "@ant-design/icons";
import ReactMarkdown from 'react-markdown';
const GptTestPromptModal = ({buttonName, title, prompt, callback}) => {
    const [open, setOpen] = useState(false);
    const [lan, setLan] = useState("CN");
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState("");
    const [form] = Form.useForm();

    const showModal = () => {
        setOpen(true)
    }
    const handleTest = () => {
        form.submit();  // This will trigger form submission
    };
    const onFinish = (values) => {
        console.log('Form Submitted with values:', values);
        setLoading(true)
        askGPT(prompt.id, values, lan).then(
            res => {
                setResult(res.data)
            }
        ).finally(() => {
            setLoading(false)
        })
    }
    const extractPlaceHolder = (sentence) => {
        const regex = /\$\{(\w+)\}/g;
        const placeholders = new Set();
        let match;
        while ((match = regex.exec(sentence)) !== null) {
            placeholders.add(match[1]);
        }
        return Array.from(placeholders);
    };

    const PlaceholderForm = ({ sentence }) => {
        const placeholders = extractPlaceHolder(sentence);
        return (
            <Form
            form={form}
            name="placeholder-form"
            onFinish={onFinish}>
                {placeholders.map((placeholder) => (
                    <Form.Item key={placeholder} name={placeholder} label={placeholder} rules={[{required:true}]}>
                        <Input/>
                    </Form.Item>
                ))}
            </Form>
        );
    };

    return (
        <>
            <a onClick={showModal} style={{display: 'flex', alignItems: 'center'}}>
                <ToolFilled style={{marginRight: 8}}/> {/* Adjust margin as needed */}
                {buttonName}
            </a>
            <Modal
                title={title}
                centered
                open={open}
                onOk={handleTest}
                okText={"Test"}
                onCancel={() => setOpen(false)}
                aria-hidden={!open}
                width={1000}
            >
                <Card>
                    <Row gutter={16}>
                        <Col span={12}>
                            <PlaceholderForm sentence={prompt.promptChinese}/>
                            <Radio.Group
                                block
                                options={["CN", "EN"]}
                                defaultValue="CN"
                                optionType="button"
                                buttonStyle="solid"
                                onChange={(e) => setLan(e.target.value)}
                                style={{marginBottom: "10px"}}
                            />
                            <Card>
                                {lan === "CN" ? prompt.promptChinese : prompt.promptEnglish}
                            </Card>
                        </Col>

                        <Col span={12}>
                            {prompt.jsonMode?<JsonViewer jsonString={JSON.stringify(result)} loading={loading}/>:
                                <Card loading={loading}><ReactMarkdown>{result}</ReactMarkdown></Card>}
                        </Col>
                    </Row>
                </Card>
            </Modal>
        </>
    )
}
export default GptTestPromptModal