import React, { useRef, useState } from 'react';
import { Card, Divider, Space, Typography, Button } from 'antd';
import { AudioOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

const MAX_DEFINITION_LENGTH = 50; // Set your desired max length for definitions

const DataCard = ({ word, entry }) => {
    const audioRefUK = useRef(null);
    const audioRefUS = useRef(null);
    const [showMoreDefinitions, setShowMoreDefinitions] = useState(false);
    const [showMoreExamples, setShowMoreExamples] = useState(false);

    // Check if entry is valid and has the required properties
    if (!entry || !entry.POS || !entry.data) {
        return null; // Return null instead of an empty string
    }

    return (
        <Card style={{ margin: '16px 0', borderRadius: '8px', width: '500px', padding: '10px' }} hoverable>
            {/* Display word as the title with audio icons for pronunciation */}
            <Space direction="vertical" style={{ width: '100%' }}>
                <Space align="baseline">
                    <Title level={4} style={{ fontSize: '18px', margin: '0' }}>{word}</Title>
                    {/* Display audio icons for UK and US pronunciation */}
                    <Space>
                        {entry.data.UK_audio_links && entry.data.UK_audio_links.length > 0 && (
                            <Space align="baseline">
                                <AudioOutlined
                                    style={{ fontSize: '16px', color: '#1890ff', cursor: 'pointer' }}
                                    onClick={() => {
                                        audioRefUK.current.play();
                                    }}
                                />
                                <Text style={{ fontSize: '12px' }}>UK</Text>
                            </Space>
                        )}
                        {entry.data.US_audio_links && entry.data.US_audio_links.length > 0 && (
                            <Space align="baseline">
                                <AudioOutlined
                                    style={{ fontSize: '16px', color: '#1890ff', cursor: 'pointer' }}
                                    onClick={() => {
                                        audioRefUS.current.play();
                                    }}
                                />
                                <Text style={{ fontSize: '12px' }}>US</Text>
                            </Space>
                        )}
                    </Space>
                    <audio ref={audioRefUK} src={entry.data.UK_audio_links[0][0]} preload="none" />
                    <audio ref={audioRefUS} src={entry.data.US_audio_links[0][0]} preload="none" />
                </Space>
                <Text type="secondary" style={{ fontSize: '14px', marginBottom: '8px', display: 'block' }}>
                    {entry.POS.join(', ')}
                </Text>
            </Space>

            {/* English Definitions */}
            {entry.data.definitions && entry.data.definitions.length > 0 && (
                <>
                    <Text type="secondary" strong style={{ fontSize: '12px' }}>
                        Definition: {showMoreDefinitions
                        ? entry.data.definitions.join(', ')
                        : entry.data.definitions[0].length > MAX_DEFINITION_LENGTH
                            ? entry.data.definitions[0].slice(0, MAX_DEFINITION_LENGTH) + '...'
                            : entry.data.definitions[0]}
                    </Text>
                    { (
                        <Button
                            type="link"
                            onClick={() => setShowMoreDefinitions(!showMoreDefinitions)}
                            style={{ padding: 0 }}
                        >
                            {showMoreDefinitions ? 'Show Less' : 'Show More'}
                        </Button>
                    )}
                    <Divider style={{ margin: '4px 0' }} />
                </>
            )}

            {/* Chinese Definitions */}
            {entry.data.definitions_translations && entry.data.definitions_translations.length > 0 && (
                <>
                    <Text type="danger" strong style={{ fontSize: '12px' }}>
                        中文定义: {entry.data.definitions_translations[0]}
                    </Text>
                    <Divider style={{ margin: '4px 0' }} />
                </>
            )}

            {/* English Examples */}
            {entry.data.examples && entry.data.examples.length > 0 && (
                <>
                    <Text italic style={{ fontSize: '12px', display: 'block' }}>
                        Example: {entry.data.examples[0][0]}
                    </Text>
                    {showMoreExamples && entry.data.examples[0].slice(1).map((example, idx) => (
                        <Text key={idx} italic style={{ fontSize: '12px', display: 'block' }}>
                            Example: {example}
                        </Text>
                    ))}
                    {entry.data.examples[0].length > 1 && (
                        <Button
                            type="link"
                            onClick={() => setShowMoreExamples(!showMoreExamples)}
                            style={{ padding: 0 }}
                        >
                            {showMoreExamples ? 'Show Less' : 'Show More'}
                        </Button>
                    )}
                    <Divider style={{ margin: '4px 0' }} />
                </>
            )}

            {/* Chinese Examples */}
            {entry.data.examples_translations && entry.data.examples_translations.length > 0 && (
                <>
                    <Text italic type="danger" style={{ fontSize: '12px', display: 'block' }}>
                        示例: {entry.data.examples_translations[0][0]}
                    </Text>
                    {showMoreExamples && entry.data.examples_translations[0].slice(1).map((chineseExample, idx) => (
                        <Text key={idx} italic type="danger" style={{ fontSize: '12px', display: 'block' }}>
                            示例: {chineseExample}
                        </Text>
                    ))}
                    {entry.data.examples_translations[0].length > 1 && (
                        <Button
                            type="link"
                            onClick={() => setShowMoreExamples(!showMoreExamples)}
                            style={{ padding: 0 }}
                        >
                            {showMoreExamples ? 'Show Less' : 'Show More'}
                        </Button>
                    )}
                    <Divider style={{ margin: '4px 0' }} />
                </>
            )}
        </Card>
    );
};

export default DataCard;
