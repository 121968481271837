// src/Login.js
import React, { useState } from 'react';
import {Navigate, useNavigate} from 'react-router-dom';
import { useAuth } from './AuthContext';
import './Login.css';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const { login } = useAuth();
    if (window.localStorage.getItem("username")) {
        return <Navigate to="/users" />;
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        // Simple authentication logic (replace with your actual auth logic)
        if (username === 'admin' && password === 'o0kZB4570a7UELiRKP4e') {
            login();
            window.localStorage.setItem("username", username)
            navigate('/users');
        } else {
            alert('Invalid credentials');
        }
    };

    return (
        <div className="login-container">
            <div className="login-box">
                <h2>Login</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Username:</label>
                        <input
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Password:</label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit" className="login-button">Login</button>
                </form>
            </div>
        </div>
    );
};

export default Login;
