import {Button, List, Modal, Pagination} from "antd";
import React, { useEffect, useState } from 'react';
import axios from "axios";
import {baseURL} from "../service/ApiService";
import {DownloadOutlined} from "@ant-design/icons";
const ViewLogsModal = ({buttonName, user}) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [logs, setLogs] = useState([]);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const updateList = () => {
        axios.get(`${baseURL}/api/web/log?userId=${user.id}&page=${currentPage-1}`).then(
            (res) => {
                console.log(res.data.content);
                setLogs(res.data.content);
                setTotal(res.data.totalElements);
                setLoading(false);
            }
        );
    }
    useEffect(() => {
        updateList();
    }, [currentPage])
    const showModal = () => {
        setLoading(true)
        updateList();
        setOpen(true)
    }
    const downloadLog = (log) => {
        // Create the content of the log
        const logContent = log.logs.replace(/\\n/g, '\n');  // do replacement for character '\n' here, replace it with newline

        // Create a blob from the content
        const blob = new Blob([logContent], { type: 'text/plain' });

        // Create a download link
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${log.timestamp}.log`; // The log file name

        // Append link to the body and trigger download
        document.body.appendChild(link);
        link.click();

        // Remove the link after download
        document.body.removeChild(link);
    };
    return (
        <>
            <a onClick={showModal}>{buttonName}</a>
            <Modal
                title={`${user.name}'s Logs`}
                centered
                open={open}
                onCancel={() => setOpen(false)}
                footer={null}
            >
                <List
                    size="large"
                    loading={loading}
                    bordered
                    dataSource={logs}
                    renderItem={
                    (log) => <List.Item>
                        <div style={{display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between"}}>
                            <span>
                                {new Date(log.timestamp).toLocaleString()}
                            </span>
                            <Button icon={<DownloadOutlined/>} onClick={() => downloadLog(log)}>Download</Button>

                        </div>
                    </List.Item>
                }
                />
                <Pagination defaultCurrent={currentPage} total={total} onChange={(page, pageSize) => setCurrentPage(page)} />
            </Modal>
        </>
    )
}

export default ViewLogsModal;