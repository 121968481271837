import React, { useState } from 'react';
import {Card} from "antd";

// Recursive component to render JSON nodes
const JsonNode = ({ data, isRoot = false }) => {
    const [expanded, setExpanded] = useState(isRoot); // Expand root node by default

    const toggleExpand = () => setExpanded(!expanded);

    if (typeof data === 'object' && data !== null) {
        return (
            <div style={{ marginLeft: 20 }}>
        <span onClick={toggleExpand} style={{ cursor: 'pointer', color: 'blue' }}>
          {expanded ? '-' : '+'}
        </span>{' '}
                {Array.isArray(data) ? '[Array]' : '{Object}'}
                {expanded && (
                    <div style={{ paddingLeft: 10 }}>
                        {Object.entries(data).map(([key, value]) => (
                            <div key={key}>
                                <strong>{key}:</strong> <JsonNode data={value} />
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    }

    return <span>{JSON.stringify(data)}</span>;
};

// Main component
const JsonViewer = ({ jsonString, loading }) => {
    let jsonData;
    try {
        jsonData = JSON.parse(jsonString);
    } catch (e) {
        return <div>Error parsing JSON</div>;
    }

    return (
        <Card loading={loading}>
            <JsonNode data={jsonData} isRoot={true} /> {/* Set isRoot to true for the root node */}
        </Card>
    );
};

export default JsonViewer;
