import React, {useEffect, useState} from "react";
import {Button, Card, Col, Image, message, Modal, Radio, Row, Select, Space} from "antd";
import axios from "axios";
import {baseURL} from "../service/ApiService";
import TextArea from "antd/es/input/TextArea";

const ImageGenerateModal = ({buttonName, title, callback}) => {
    const [open, setOpen] = useState(false);
    const [image, setImage] = useState(null);
    const [prompt, setPrompt] = useState("");
    const [size, setSize] = useState("256x256");
    const [loading, setLoading] = useState(false);
    const generateImage = async (prompt, size) => {
        setLoading(true)
        axios.get(`${baseURL}/api/web/getImgAI?prompt=${prompt}&size=${size}`).then(
            (res) => {
                console.log(res.data);
                setImage(res.data);
                setLoading(false)
                callback()
            }
        );
    }

    return (
        <>
            <Button type={"primary"} onClick={() => setOpen(true)}>{buttonName}</Button>
            <Modal
                title={title}
                centered
                open={open}
                onOk={() => {
                    generateImage(prompt, size)
                }}
                okText={"Generate"}
                onCancel={() => setOpen(false)}
                width={800}
            >
                <Card>
                    <Row gutter={16} justify={"space-between"}>
                        <Col span={12}>
                            <div style={{marginBottom: '16px'}}>
                                <label htmlFor="prompt">Prompt:</label>
                                <TextArea
                                    id="prompt"
                                    value={prompt}
                                    onChange={(e) => setPrompt(e.target.value)}
                                    rows={4}
                                    placeholder="Enter your prompt here..."
                                />
                            </div>
                            <div style={{marginBottom: '16px'}}>
                                <label htmlFor="size">Size:</label>
                                <Select
                                    id="size"
                                    value={size}
                                    onChange={(value) => setSize(value)}
                                    style={{width: '200px', marginLeft: '8px'}}
                                >
                                    <Select.Option value="256x256">256x256</Select.Option>
                                    <Select.Option value="256x512">256x512</Select.Option>
                                    <Select.Option value="512x256">512x256</Select.Option>
                                    <Select.Option value="512x512">512x512</Select.Option>
                                </Select>
                            </div>
                        </Col>
                        <Col span={12}>
                            <Card loading={loading}>
                                {image ? <Image src={`data:image/jpeg;base64,${image.image}`}/> : ''}
                            </Card>
                        </Col>
                    </Row>
                </Card>
            </Modal>
        </>
    )
}
export default ImageGenerateModal