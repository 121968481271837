import * as React from "react";
import * as ReactDOM from "react-dom/client";
import Root from "./root";
import Login from "./Login";
import {createBrowserRouter, Navigate, RouterProvider} from "react-router-dom";
import { AuthProvider } from "./AuthContext";
import ProtectedRoute from "./ProtectedRoute";
import "./index.css";
import Home from "./Home";
import Contact from "./Contact";
import GptPromptTable from "./gptPrompt/GptPromptTable";
import UserFeedBack from "./gptPrompt/UserFeedBack";
import Books from "./gptPrompt/Books";
import UserTable from "./table/UserTable";
import ImageTable from "./table/ImageTable";
import WordTable from "./table/WordTable";

const router = createBrowserRouter([
    {
        path: "/",
        element: (
            <ProtectedRoute>
                <Root />
            </ProtectedRoute>
        ),
        children: [
            {
                path: "/", // Redirect from root to /prompt
                element: <Navigate to="/prompt" replace />,
            },
            {
                path: "/home",
                element: (
                    <ProtectedRoute>
                        <Home />
                    </ProtectedRoute>
                ),
            },
            {
                path: "/contact",
                element: (
                    <ProtectedRoute>
                        <Contact />
                    </ProtectedRoute>
                ),
            },
            {
                path: "/prompt",
                element: (
                    <ProtectedRoute>
                        <GptPromptTable />
                    </ProtectedRoute>
                ),
            },
            {
                path: "/feedback",
                element: (
                    <ProtectedRoute>
                        <UserFeedBack />
                    </ProtectedRoute>
                ),
            },
            {
                path: "/books",
                element: (
                    <ProtectedRoute>
                        <Books />
                    </ProtectedRoute>
                ),
            },
            {
                path: "/books/:bookId", // Dynamic route for specific book details
                element: (
                    <ProtectedRoute>
                        <WordTable />
                    </ProtectedRoute>
                ),
            },
            {
                path: "/images",
                element: (
                    <ProtectedRoute>
                        <ImageTable />
                    </ProtectedRoute>
                ),
            },
            {
                path: "/users",
                element: (
                    <ProtectedRoute>
                        <UserTable />
                    </ProtectedRoute>
                ),
            },
        ],
    },
    {
        path: "/login",
        element: <Login />,
    },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
    <AuthProvider>
        <RouterProvider router={router} />
    </AuthProvider>
);
