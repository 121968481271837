import {googleTTS} from "../service/api/userAPI";
import {Button, Input} from "antd";
import {useState} from "react";
import TextArea from "antd/es/input/TextArea";

const AudioTable = () => {
    const [text, setText] = useState("");
    const playAudio = () => {
        googleTTS(text).then(
            (res) => {
                // Assuming `res` is a base64 string
                const audio = new Audio(`data:audio/mp3;base64,${res.data}`);

                // Ensure the play is triggered after the user interaction (click)
                audio.play().catch((err) => {
                    console.error("Error playing audio: ", err);
                });
            }
        ).catch((err) => {
            console.error("Error fetching TTS response: ", err);
        });
    }
    return (
        <div>
            Audio Test Playground
            <TextArea onChange={e => setText(e.target.value)} rows={8}></TextArea>
            <Button onClick={playAudio} type={"primary"}>play</Button>
        </div>
    )
}
export default AudioTable;