import React, {useState} from "react";
import {Col, Modal, Row, Switch} from "antd";
import axios from "axios";
import {baseURL} from "../service/ApiService";
import TextArea from "antd/es/input/TextArea";
import {EditFilled} from "@ant-design/icons";

const GptPromptEditModal = ({buttonName, title, prompt, callback}) => {
    const [open, setOpen] = useState(false);
    const [currentChinesePrompt, setCurrentChinesePrompt] = useState(prompt.promptChinese);
    const [currentEnglishPrompt, setCurrentEnglishPrompt] = useState(prompt.promptEnglish);
    const [enableJson, setEnableJson] = useState(prompt.jsonMode);
    const [currentSchema, setCurrentSchema] = useState(prompt.config);

    const showModal = () => {
        setOpen(true)
    }
    const save = (id) => {
        const data = {
            id: id,
            chinesePrompt: currentChinesePrompt,
            englishPrompt: currentEnglishPrompt,
            enableJson: enableJson,
            jsonSchema: currentSchema,
        }
        axios.put(`${baseURL}/api/prompt`, data).then(
            () => {
                callback();
                setOpen(false);
            }
        )
    }
    return (
        <>
            <a onClick={showModal} style={{display: 'flex', alignItems: 'center'}}>
                <EditFilled style={{marginRight: 8}}/> {/* Adjust margin as needed */}
                {buttonName}
            </a>
            <Modal
                title={prompt.description}
                centered
                open={open}
                onOk={() => save(prompt.id)}
                onCancel={() => setOpen(false)}
                okText="Save"
                width={1000}
                aria-hidden={!open}
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <h4>Chinese Prompt</h4>
                        <TextArea autoSize={true} value={currentChinesePrompt}
                                  onChange={(e) => setCurrentChinesePrompt(e.target.value)}/>
                        <h4>English Prompt</h4>
                        <TextArea autoSize={true} value={currentEnglishPrompt}
                                  onChange={(e) => setCurrentEnglishPrompt(e.target.value)}/>
                    </Col>
                    <Col span={12}>
                        <div style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
                            <h4 style={{margin: 0, marginRight: '10px'}}>JSON Schema</h4>
                            <Switch
                                checked={enableJson}
                                onChange={setEnableJson}
                            />
                        </div>
                        {
                            enableJson ? <TextArea autoSize={true} value={currentSchema}
                                                   onChange={(e) => setCurrentSchema(e.target.value)}/> : ''
                        }
                    </Col>
                </Row>
            </Modal>
        </>
    )
}
export default GptPromptEditModal