import {
    DownOutlined,
    InboxOutlined,
    LoadingOutlined,
    MenuOutlined,
    PoweroffOutlined,
    UploadOutlined
} from '@ant-design/icons';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {Button, message, Modal, Typography, Table, Tag, Dropdown, Space} from 'antd';
import axios from 'axios';
import {baseURL} from '../service/ApiService';


import {
    arrayMove,
    SortableContext,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, { useEffect, useState } from 'react';
import Dragger from "antd/es/upload/Dragger";
import BookEditFormModal from "../modal/BookEditFormModal";
import GptPromptEditModal from "../modal/GptPromptEditModal";
import GptTestPromptModal from "../modal/GptTestPromptModal";

const Books = () => {
    const [dataSource, setDataSource] = useState([]);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [uploading, setUploading] = useState(false);
    const [fileList, setFileList] = useState([]);

    const { Text, Link } = Typography;
    const updateTable = async () => {
        axios.get(`${baseURL}/api/book/all`).then(
            (res) => {
                setDataSource(res.data.map(item => ({
                    key: item.id,
                    id: item.id,
                    description: item.description,
                    title: item.title,
                    englishDescription: item.englishDescription,
                    englishTitle: item.englishTitle,
                    wordNum: item.wordNum,
                    userNum: item.userNum
                })));
                setLoading(false);
            }
        );
    }

    useEffect(() => {
        updateTable();
    }, []);

    const onDragEnd = ({ active, over }) => {
        if (active.id !== over?.id) {
            setDataSource((previous) => {
                const activeIndex = previous.findIndex((i) => i.key === active.id);
                const overIndex = previous.findIndex((i) => i.key === over?.id);
                return arrayMove(previous, activeIndex, overIndex);
            });
        }
    };

    const Row = ({ children, ...props }) => {
        const {
            attributes,
            listeners,
            setNodeRef,
            setActivatorNodeRef,
            transform,
            transition,
            isDragging,
        } = useSortable({
            id: props['data-row-key'],
        });

        const style = {
            ...props.style,
            transform: CSS.Transform.toString(
                transform && {
                    ...transform,
                    scaleY: 1,
                },
            ),
            transition,
            ...(isDragging
                ? {
                    position: 'relative',
                    zIndex: 9999,
                }
                : {}),
        };

        return (
            <tr {...props} ref={setNodeRef} style={style} {...attributes}>
                {React.Children.map(children, (child) => {
                    if (child.key === 'sort') {
                        return React.cloneElement(child, {
                            children: (
                                <MenuOutlined
                                    ref={setActivatorNodeRef}
                                    style={{
                                        touchAction: 'none',
                                        cursor: 'move',
                                    }}
                                    {...listeners}
                                />
                            ),
                        });
                    }
                    return child;
                })}
            </tr>
        );
    };

    const columns = [
        {
            key: 'sort',
            width: 100,
        },
        {
            title: 'ID',
            dataIndex: 'id',
            width: 100,
            defaultSortOrder: 'ascend',
            sorter: (a, b) => a.id - b.id,
        },
        {
            title: 'Name',
            dataIndex: 'title',
            width: 200,
        },
        {
            title: 'Description',
            dataIndex: 'description',
        },
        {
            title: 'English Name',
            dataIndex: 'englishTitle',
            width: 200,
        },
        {
            title: 'English Description',
            dataIndex: 'englishDescription',
        },
        {
            title: 'Words',
            dataIndex: 'wordNum',
            width: 100,
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.wordNum - b.wordNum,
        },
        {
            title: 'Users',
            dataIndex: 'userNum',
            width: 100,
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.userNum - b.userNum,
        },
        {
            title: 'Action',
            key: 'operation',
            fixed: 'right',
            width: 100,
            render: (row) => {
                return (
                    <Dropdown
                        menu={{
                            items: [
                                {
                                    key: 'editBook',
                                    label: (<BookEditFormModal buttonName={"Edit"} book={row} updateBookList={updateTable}></BookEditFormModal>),
                                },
                                {
                                    key: 'viewBook',
                                    label: (<a href={`/books/${row.id}`}>View Book</a>),
                                },
                            ],
                        }}
                    >
                        <a onClick={(e) => e.preventDefault()}>
                            <Space>
                                Action
                                <DownOutlined />
                            </Space>
                        </a>
                    </Dropdown>
                );
            }
        },
    ];
    const beforeUpload = (file) => {
        const maxSize = 20 * 1024 * 1024; // 20 MB
        const isCSV = file.type === 'text/csv' || file.name.endsWith('.csv');

        if (!isCSV) {
            message.error('You can only upload CSV files.');
            return false; // Prevent upload
        }

        if (file.size > maxSize) {
            message.error('File size exceeds 20MB.');
            return false; // Prevent upload
        }
        setFileList([...fileList, file]); // Add file to the list
        return false; // Prevent automatic upload
    };
    const handleRemove = (file) => {
        setFileList(fileList.filter(item => item.uid !== file.uid));
    };
    const props = {
        name: 'file',
        multiple: true,
        fileList,
        beforeUpload,
        onRemove: handleRemove, // Handle file removal
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };
    const handleUploadFiles = () => {
        if (fileList.length === 0) {
            message.info('No file uploaded');
        } else {
            message.info(`${fileList.length} files ready to be uploaded`);
            setUploading(true);

            setUploading(false);
            const formData = new FormData();
            fileList.forEach(file => {
                formData.append('files', file);
            });
            axios.post(`${baseURL}/api/book/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }}).then(
                () => {
                    setFileList([]); // Clear the file list after upload
                    message.success('Files uploaded successfully');
                    updateTable();
                }
            );
        }
    };

    return (
        <>
            <Button
                type="primary"
                icon={<UploadOutlined />}
                onClick={() => { setOpen(true)}}
            >
                Upload new book
            </Button>
            <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
                    <SortableContext
                        items={dataSource.map((i) => i.key)}
                        strategy={verticalListSortingStrategy}
                    >
                        <Table
                            components={{
                                body: {
                                    row: Row,
                                },
                            }}
                            rowKey="key"
                            columns={columns}
                            dataSource={dataSource}
                            showSorterTooltip={{
                                target: 'sorter-icon',
                            }}
                            loading={loading}
                        />
                    </SortableContext>
                </DndContext>
            <Modal
                title='Upload a new book'
                centered
                open={open}
                onCancel={() => setOpen(false)}
                onOk={() => handleUploadFiles(fileList)}
                okButtonProps={{ loading: uploading }}
                okText="Save"
                width={1000}
            >
                <div>
                    <Text italic>Please name CSV file with book name and put word row by row</Text>
                </div>
                <Dragger {...props}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload (20M maximum)</p>
                    <p className="ant-upload-hint">
                        Support for csv file only.
                    </p>
                </Dragger>
            </Modal>
        </>
    )
};

export default Books;
