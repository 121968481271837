import React, { useEffect, useState } from 'react';
import {formatTimestamp, convertTimestampToElapsedTime} from "../util/DateUtils";
const DynamicTime = ({ timestamp, gap = '5px', fontSize = 'small'}) => {
    const [elapsedTime, setElapsedTime] = useState(convertTimestampToElapsedTime(timestamp));

    useEffect(() => {
        const interval = setInterval(() => {
            setElapsedTime(convertTimestampToElapsedTime(timestamp));
        }, 1000);

        return () => clearInterval(interval); // Cleanup on component unmount
    }, [timestamp]);

    return (
            <div style={{ display: 'flex', flexDirection: 'column', gap }}>
                <span>{formatTimestamp(timestamp)}</span>
                <span style={{ fontSize }}>({elapsedTime})</span>
            </div>
        )
};

export default DynamicTime;
